<template>
<div id="page" class="share-rule-page">
  <div class="srp-select">
    设备类型：
    <el-select v-model="select.deviceType" @change="selectChange()">
      <el-option placeholder="请选择" v-for="(item, index) in select.deviceOption" :key="index" :label="item.txt_type_name" :value="item.txt_type_code" />
    </el-select>
  </div>
  <div class="srp-tables row-start-start">
    <div class="srp-left">
      <el-table class="srpl-table" :row-class-name="getRowClass" @row-click="getRightData" :data="table.ruleData" v-loading="table.ruleTableLoading">
        <el-table-column prop="txt_tp_name" align="center" label="方案名称" />
        <el-table-column prop="txt_combined_tp_name" align="center" label="联检主方案名称">
          <template #default="scope">{{scope.row.txt_combined_tp_name||"- -"}}</template>
        </el-table-column>
        <el-table-column prop="dec_tp_fee_amount" align="center" label="方案金额（元）" />
        <el-table-column prop="txt_tp_target" align="center" label="适用对象" />
      </el-table>
      <!-- <pagination :total="page.totalLeft" @sizeChange="(e) => {page.limitLeft=e;getLeftData()}" @pageChange="(e) => {page.pageLeft=e;getLeftData()}" /> -->
    </div>
    <div class="srp-right">
      <el-table class="srpl-table" :data="table.detailData" v-loading="table.detailTableLoading">
        <el-table-column prop="txt_split_description" align="center" label="规则明细" />
        <el-table-column prop="dec_divide_amount" align="center" label="分账金额（元）" />
      </el-table>
      <!-- <pagination :total="page.totalRight" @sizeChange="(e) => {page.limitRight=e;getRightData()}" @pageChange="(e) => {page.pageRight=e;getRightData()}" /> -->
    </div>
  </div>
</div>
</template>

<script>
// 总账管理 - 分润规则
import { ElMessage } from "element-plus";
// getCurrentInstance, 
import { onMounted, reactive, toRefs } from 'vue';
import { getDeviceType, getFetchTpByTypecode, getFetchDivideConfigByRuleid } from "api/apis.js";
// import { Pagination } from "components";
export default {
  // components: {
  //   Pagination,
  // },
  setup() {
    // const instance = getCurrentInstance();
    const state = reactive({
      page: {
        totalLeft: 0,
        limitLeft: 10,
        pageLeft: 1,
        totalRight: 0,
        limitRight: 10,
        pageRight: 1
      },
      select: {
        deviceType: null,
        deviceOption: []
      },
      table: {
        ruleTableLoading: false,
        detailTableLoading: false,
        ruleData: [],
        detailData: []
      }
    });

    onMounted(() => {
      getDevices();
    });

    const selectChange = () => {
      getLeftData();
    };

    // 获取设备类型
    const getDevices = () => {
      getDeviceType().then(response => {
        if (response.code == 200) {
          state.select.deviceOption = response.data;
          state.select.deviceType = state.select.deviceOption[0].txt_type_code;
          getLeftData();
        } else {
          state.select.deviceOption = [];
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(e);
      })
    };

    // 设置 省代 表格 行样式
    const getRowClass = ({ row }) => {
      if (row.focus) {
        return "share-main-row-class";
      } else {
        return "";
      }
    };

    // 按设备类型获取分润配置
    const getLeftData = () => {
      state.table.ruleTableLoading = true;
      state.table.detailData = [];
      getFetchTpByTypecode({
        device_type_code: state.select.deviceType
      }).then(response => {
        if (response.code == 200) {
          state.table.ruleData = response.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.ruleTableLoading = false;
      })
    };

    // 根据分润规则获取分润配置
    const getRightData = (row) => {
      row.focus = true;
      let lastShareRuleFocusRow = JSON.parse(localStorage.getItem("lastShareRuleFocusRow"));
      if (lastShareRuleFocusRow && lastShareRuleFocusRow.t_divide_rule_id != row.t_divide_rule_id) {
        let tempIndex = state.table.ruleData.findIndex(t => t.t_divide_rule_id == lastShareRuleFocusRow.t_divide_rule_id);
        if (tempIndex != -1) {
          state.table.ruleData[tempIndex].focus = false;
        }
      }
      // instance.ctx.$forceUpdate();
      localStorage.setItem("lastShareRuleFocusRow", JSON.stringify(row));
      if (row.t_divide_rule_id != null) {
        state.table.detailTableLoading = true;
        getFetchDivideConfigByRuleid({
          ruleid: row.t_divide_rule_id
        }).then(response => {
          if (response.code == 200) {
            state.table.detailData = response.data;
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.detailTableLoading = false;
        })
      } else {
        state.table.detailData = [];
      }
    };

    return {
      ...toRefs(state),
      getDevices,
      selectChange,
      getLeftData,
      getRightData,
      getRowClass
    }
  }
}
</script>

<style lang="scss">
.share-rule-page {
  .srp-tables {
    margin-top: 6px;
    height: calc(100% - 40px);

    .srp-left {
      width: 60%;
      height: 100%;
      padding-right: 6px;
      box-sizing: border-box;
      border-right: 1px solid #ebeef5;
    }

    .srp-right {
      height: 100%;
      padding-left: 6px;
      box-sizing: border-box;
      width: calc(40% - 1px);
    }

    .el-table {
      height: calc(100% - 40px);
    }
  }

  .el-table {
    tbody {
      .share-main-row-class:hover>td {
        background-color: #89c3f0 !important;
      }
    }
    .el-table__row {
      cursor: pointer;

      &.share-main-row-class {
        background-color: #89c3f0 !important;
      }
    }
  }
}
</style>
